<script lang="ts">
	import { page } from '$app/stores';
	import { getModalStore } from '@skeletonlabs/skeleton';

	import CTASubscribePage from '../../../modules/marketing/CTASubscribePage.svelte';

	const modalStore = getModalStore();

	// If this modal opens, but a profile is later detected, close the modal!
	$: if ($page.data.isSubscribed) {
		modalStore.clear();
	}
</script>

<div data-testid="cta-subscribe-modal" class="px-2">
	<div class="flex justify-end">
		<button type="button" class="btn-icon" on:click={() => modalStore.clear()}>
			<iconify-icon icon="tabler:x" />
			<span class="sr-only">Close modal</span>
		</button>
	</div>
	<CTASubscribePage isTransitionEnabled={false} />
</div>
