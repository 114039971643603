import type { ModalSettings } from '@skeletonlabs/skeleton';

import type { ModalStore } from '../../../../types';

import CtaSubscribeModal from './_internal/CTASubscribeModal.svelte';

const ctaSubscribeModalSettings: ModalSettings = {
	backdropClasses: '!backdrop-blur-lg !bg-surface-900/90',
	component: {
		ref: CtaSubscribeModal
	},
	position: 'pt-4 tablet:!pt-8 tablet:!pb-0',
	type: 'component'
};

export const openCtaSubscribeModal = (modalStore: ModalStore) => {
	modalStore.trigger({ ...ctaSubscribeModalSettings });
};
